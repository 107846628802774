import React, { Fragment } from "react";
import styled from "styled-components";
import { PageCard } from "../../components/card";
import { lightGray } from "../../global/colors";
import Event from "./event";
import { WIP_DATA } from "./data";

const Card = () => (
  <WIPCardWrapper>
    <WIPCard>
      {WIP_DATA.map((event, index) => (
        <Fragment key={event.id}>
          <Event {...event} />
          {index !== WIP_DATA.length - 1 && <HorizontalRule />}
        </Fragment>
      ))}
    </WIPCard>
  </WIPCardWrapper>
);

const WIPCardWrapper = styled.div`
  margin-bottom: 32px;
  padding: 0 16px;

  @media (min-width: 375px) {
    padding: 0 32px;
  }
`;

const WIPCard = styled(PageCard)`
  position: relative;
  max-width: 448px;
  padding: 48px;

  @media (min-width: 540px) {
    padding: 64px 128px;
  }

  @media (min-width: 670px) {
    padding: 80px 160px;
  }

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 48px;
  }
`;

const HorizontalRule = styled.hr`
  background-color: ${lightGray};
  margin: 32px auto;
  width: 128px;
`;

export default Card;
