export const ABOUT_HEADER = {
  title: "About",
  blurb:
    "Just another person who loves to travel and wants to share some " +
    "recommendations. I am the Gilded Panda (TGP) and my travel partner is " +
    "ccrazy88 (CC)."
};

export const ABOUT_DATA = [
  {
    title: "Other Likes",
    details: [
      "Baby Elephants",
      "Bullet Journalling",
      "Flowers",
      "Houseplants & Succulents",
      "If it can be crafted, I’ll try",
      "Octopuses"
    ],
    id: 1
  },
  {
    title: "Special Thanks",
    details: [
      "Designs: Kavita",
      "Developer help: Chrisna",
      "Travel companion: Chrisna"
    ],
    address: [
      "I wouldn’t be able to make this",
      "website without their support"
    ],
    id: 2
  }
];
