import React, { Fragment } from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Card from "./card";
import PaintedLadies from "./images/painted-ladies";
import { ABOUT_HEADER } from "./data";

const About = () => (
  <Fragment>
    <main>
      <SectionHeader {...ABOUT_HEADER} svg={PaintedLadies} />
      <Card />
    </main>
    <Footer />
  </Fragment>
);

export default About;
