import styled from "styled-components";

const Card = styled.article`
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
`;

export const PageCard = styled(Card)`
  border-radius: 16px;
  margin: 0 auto;
  max-width: 400px;
  padding: 24px;
  text-align: center;

  @media (min-width: 670px) {
    padding: 32px;
  }
`;

export const GridCard = styled(Card)`
  margin: 16px 0;
  padding: 24px;

  @supports (display: grid) {
    @media (min-width: 670px) {
      margin: 0;
    }
  }
`;
