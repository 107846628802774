import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import { SectionHeaderP as P } from "./p";
import { SectionH1 as H1 } from "./h1";

const SectionHeader = ({ title, blurb, svg: Svg }) => (
  <Header>
    <H1>{title}</H1>
    <P>{blurb}</P>
    <Svg />
  </Header>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  svg: PropTypes.func.isRequired
};

export default SectionHeader;
