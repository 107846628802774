import React from "react";
import { lightGray } from "../../../global/colors";

const BorderRight = props => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    stroke={lightGray}
    strokeLinecap="round"
    strokeMiterlimit={10}
    viewBox="0 0 40 310"
    {...props}
  >
    <path d="M27.227 226.459v34.729a4 4 0 0 1-4 4H8.037a5 5 0 0 0-5 5v38.687M14.369 280.997H3.037M16.873 280.792c0-.946.767-1.713 1.713-1.713M25.324 222.246a1.714 1.714 0 1 0 3.427-.001 1.714 1.714 0 0 0-3.427.001zM33.537 169.959a1.714 1.714 0 1 0 3.427-.001 1.714 1.714 0 0 0-3.427.001z" />
    <path d="M18.754 276.457v-33.498a3.667 3.667 0 0 1 3.666-3.666h9.27a3.667 3.667 0 0 0 3.667-3.667v-62.167M27.227 85.119V50.39a4 4 0 0 0-4-4H8.037a5 5 0 0 1-5-5V1.125M27.227 93.286v125.007M14.369 30.581H3.037M18.754 77.786v156.007M16.873 30.786c0 .946.767 1.713 1.713 1.713M25.324 89.331a1.714 1.714 0 1 0 3.427-.001 1.714 1.714 0 0 0-3.427.001zM33.537 141.619c0 .946.768 1.714 1.713 1.714a1.715 1.715 0 0 0 0-3.427c-.945 0-1.713.767-1.713 1.713z" />
    <path d="M18.754 35.12v33.498a3.667 3.667 0 0 0 3.666 3.666h9.27a3.667 3.667 0 0 1 3.667 3.667v61.843M35.301 166.441v-21.089" />
  </svg>
);

export default BorderRight;
