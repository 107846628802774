import styled from "styled-components";
import { darkGray } from "../global/colors";

export const BaseP = styled.p`
  color: ${darkGray};
  font-size: 16px;
  line-height: 28px;
`;

export const SectionHeaderP = styled(BaseP)`
  margin: 16px auto;
  max-width: 50ch;
  padding: 0 16px;

  @media (min-width: 670px) {
    font-size: 18px;
    line-height: 32px;
    margin: 24px auto;
    padding: 0;
  }

  @media (min-width: 880px) {
    font-size: 20px;
    margin: 32px auto;
  }
`;
