import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { blue, gray, pink, lightGray, lightPink } from "../../global/colors";

const activeClassName = "header-link-active";

const HeaderLink = props => (
  <li>
    <HeaderNavLink {...props} activeClassName={activeClassName} />
  </li>
);

const HeaderNavLink = styled(NavLink).attrs({
  /* Add the React Router hook for the selected state. */
  activeClassName
})`
  color: ${lightGray};
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 28px;
  letter-spacing: 0.125em;
  line-height: 28px;
  margin: 0 8px;
  text-transform: uppercase;

  &:hover {
    color: ${gray};
  }

  @media (min-width: 670px) {
    color: ${pink};

    &:hover {
      color: ${lightPink};
    }
  }

  @media (min-width: 670px) {
    height: none;
    line-height: none;
    margin: 0 12px;
  }

  @media (min-width: 880px) {
    font-size: 16px;
    margin: 0 16px;
  }

  /* Add a blue top border if the link goes to the current page. */
  @media (min-width: 670px) {
    /* Try to align the border with the text. */
    padding-left: 0.125em;
    padding-top: 20px;

    &.${activeClassName} {
      border-top: 4px solid ${blue};
      padding-top: 16px;
    }
  }
`;

export default HeaderLink;
