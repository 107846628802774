import React from "react";
import styled from "styled-components";
import Link from "./link";

const Header = () => (
  <StyledHeader>
    <nav>
      <List>
        <Link exact to="/">
          Home
        </Link>
        <Link to="/japan">Japan</Link>
        <Link to="/about">About</Link>
      </List>
    </nav>
  </StyledHeader>
);

const StyledHeader = styled.header`
  @media (min-width: 670px) {
    box-shadow: 0 8px 24px 16px rgba(0, 0, 0, 0.015);
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px auto;
  max-width: 320px;
  padding: 0 16px;

  @media (min-width: 670px) {
    height: 64px;
    margin: 0;
    max-width: none;
    padding: 0;
  }
`;

export default Header;
