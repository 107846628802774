import React from "react";
import styled from "styled-components";
import { gray, lightGray, semiLightGray } from "../../../global/colors";

const PaintedLadies = props => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    viewBox="0 0 168 100"
    {...props}
  >
    <g strokeWidth={1.5}>
      <g stroke={lightGray}>
        <path d="M20.371 18.753h8.789v5.797h-8.789zM24.766 8.916V1.179M6.222 51.562V31.561M6.222 77.236V56.76M.75 94.151v4.117M2.09 90.034v4.117M3.431 85.918v4.117M4.771 81.801v4.116M15.824 94.151v4.117M17.64 90.034v4.117M19.931 85.918v4.117M20.32 81.137v17.13M23.616 31.561v20.952h4.687M10.526 31.561h8.453v16.357h-8.453zM14.753 31.561v16.357M10.526 40.128h8.438M6.533 47.918h17.083M8.815 47.918v4.594M11.784 47.918v4.594M14.753 47.918v4.594M17.722 47.918v4.594M20.69 47.918v4.594M25.518 56.072v21.504M17.496 76.573V58.666" />
        <path d="M28.892 56.072v21.164M9.55 56.072v21.164M33.213 59.892h5.858v7h-5.858z" />
        <path d="M33.213 66.683h5.858v7h-5.858zM27.797 35.14h5.859v6.006h-5.859z" />
        <path d="M27.797 40.962h5.859v6.956h-5.859zM23.794 85.206h2.642M29.584 85.206h4.543M23.794 89.6h2.642M29.584 89.6h4.543M23.794 93.994h2.642M29.584 93.994h4.543M31.708 73.683v3.055M34.677 73.683v3.055M37.646 73.683v3.055M20.371 24.55H8.278M20.371 18.754v-5.476M29.16 18.754v-5.476M20.371 13.278h8.789M25.443 62.96h-2.016v-1.359c0-.567-.459-1.026-1.025-1.026H21.36v-.884c0-.566-.459-1.025-1.026-1.025h-5.676c-.567 0-1.027.459-1.027 1.025v.884h-1.042c-.566 0-1.025.459-1.025 1.026v1.359H9.549M17.623 94.151H.75M20.32 90.034H2.09M20.32 85.918H3.447M45.087 22.535v5.651L24.766 8.915 4.444 28.186v-5.651" />
        <path d="M45.087 17.651v5.175L24.766 3.556 4.444 22.826v-5.175M43.216 27.758v24.754M43.216 56.072v21.163M43.216 81.696v8.339M4.397 52.512H45.04v3.56H4.397zM43.216 27.758H6.222v3.56h37.002M4.397 77.576H45.04v3.561H4.397zM33.657 35.14h5.858v6.006h-5.858z" />
        <path d="M33.657 40.962h5.858v6.956h-5.858zM37.275 85.206h2.642M37.275 89.6h2.642M37.275 93.994h2.642M40.614 73.683v3.055M29.367 73.683h13.849M41.253 24.55H29.16M41.712 98.268H.75" />
      </g>
      <g stroke={gray}>
        <path d="M60.92 18.8h8.789v5.797H60.92zM65.315 8.963V1.226M56.421 94.151v4.117M58.236 90.034v4.117M60.527 85.918v4.117M60.917 81.289v16.978M64.212 31.318h4.687v21.194h-4.687zM51.123 35.488h8.453v12.43h-8.453zM55.349 35.488v12.43M51.123 41.999h8.437M49.663 47.918v4.594M52.631 47.918v4.594M55.6 47.918v4.594M58.569 47.918v4.594M61.538 47.918v4.594M66.114 56.072v21.504M50.145 67.254a7.985 7.985 0 0 1 15.969 0M53.857 76.738V61.317M62.964 61.318v15.42" />
        <path d="M69.488 56.072v21.164M50.145 56.072v21.164M64.389 85.206h2.643M64.389 89.6h2.643M64.389 93.994h2.643M72.304 73.683v3.055M60.921 24.597H48.828M60.921 18.982c0-2.272 1.968-4.114 4.395-4.114 2.426 0 4.394 1.842 4.394 4.114M51.123 35.489c0-2.169 1.957-3.929 4.269-3.929 2.311 0 4.184 1.759 4.184 3.928M83.812 27.758v24.755M46.818 52.513V31.561M83.812 56.76v20.476M83.812 81.696v8.338M46.818 77.236V56.76M44.993 52.512h40.643v3.56H44.993zM83.812 27.758H44.993v3.56h38.819M41.346 94.151v4.117M42.686 90.034v4.117M44.027 85.918v4.117M45.367 81.801v4.116M44.993 77.577h40.643v3.561H44.993zM47.13 47.918h17.083M73.808 44h5.859M73.808 66.417v-5.859h5.859v5.859" />
        <path d="M73.808 64.875h5.859v4.81h-5.859zM77.872 85.206h2.642M70.179 85.206h4.544M77.872 89.6h2.642M70.179 89.6h4.544M77.872 93.994h2.642M70.179 93.994h4.544M75.273 73.683v3.055M78.242 73.683v3.055M81.211 73.683v3.055M69.964 73.683h13.849M81.802 24.597H69.709M73.808 60.365c0-1.689 1.312-3.059 2.93-3.059s2.929 1.37 2.929 3.059M73.808 45.575v-5.859h5.859v5.859" />
        <path d="M73.808 44.034h5.859v4.81h-5.859zM73.808 39.524c0-1.69 1.312-3.06 2.93-3.06s2.929 1.37 2.929 3.06M82.784 98.268H41.346M58.219 94.151H41.346M60.513 90.034H42.686M60.917 85.918H44.043M85.636 22.582v5.651L65.315 8.962 44.993 28.233V17.678" />
        <path d="M85.636 17.678v5.194L65.315 3.602l-20.322 19.27" />
      </g>
      <g stroke={lightGray}>
        <path d="M101.573 18.801h8.789v5.797h-8.789zM105.967 8.962V1.225M97.072 94.151v4.117M98.887 90.034v4.117M101.178 85.918v4.117M101.568 81.231v17.036M104.864 31.561h4.687v20.952h-4.687zM91.153 34.317h3.146v7.199h-3.146zM94.299 34.317h3.146v7.199h-3.146z" />
        <path d="M97.444 34.317h3.146v7.199h-3.146zM109.742 34.317h3.146v7.199h-3.146z" />
        <path d="M112.887 34.317h3.146v7.199h-3.146zM109.742 45.229h3.146v7.199h-3.146z" />
        <path d="M112.887 45.229h3.146v7.199h-3.146zM106.765 56.072v21.504M98.844 76.738V60.367h7.921M98.844 60.367h-7.572" />
        <path d="M110.139 56.072v21.164M90.798 56.072v21.164M105.042 85.206h2.642M110.832 85.206h4.543M105.042 89.6h2.642M110.832 89.6h4.543M105.042 93.994h2.642M110.832 93.994h4.543M101.572 18.8v-5.476M110.361 18.8v-5.476M101.572 13.324h8.789M96.099 47.956l-4.691 4.558h9.382l-4.691-4.558zM124.464 27.758v24.599M87.469 52.357V31.561M124.464 56.76v20.476M124.464 81.696v8.338M87.469 77.236V56.76" />
        <path d="M126.288 56.072v-3.56H85.645v3.56h40.643zM124.476 27.758H85.645v3.561h38.742M81.998 94.151v4.117M83.338 90.034v4.117M84.678 85.918v4.117M86.018 81.801v4.117M85.645 77.577h40.643v3.561H85.645z" />
        <path d="M109.552 31.561h9.817v20.952h-9.817z" />
        <path d="M116.033 34.317h3.146v7.199h-3.146zM116.033 45.229h3.146v7.199h-3.146zM114.462 59.892h5.858v5.412h-5.858z" />
        <path d="M114.462 65.141h5.858v5.412h-5.858zM118.523 85.206h2.642M118.523 89.6h2.642M118.523 93.994h2.642M110.615 70.553h13.849M101.572 24.597H89.479M122.454 24.597h-12.093M96.099 44l-8.764 8.515h17.529L96.099 44zM112.887 77.576v-4.579h9.091v4.579M122.96 98.268H81.998M98.871 94.151H81.998M101.253 90.034H83.338M101.568 85.918H84.695M126.288 22.582v5.651L105.967 8.962 85.645 28.233l-.009-10.555" />
        <path d="M126.288 17.697v5.175l-20.321-19.27-20.322 19.27" />
      </g>
      <g stroke={semiLightGray}>
        <path d="M146.62 8.487V.75M165.117 31.561v20.436M165.117 56.76v20.476M165.117 81.695v16.572M137.725 94.151v4.117M139.541 90.034v4.117M141.832 85.918v4.117M142.221 81.379v16.888M150.206 31.561h9.816v20.951h-9.816zM145.518 31.561h4.688v20.951h-4.688zM132.429 31.561h8.453v16.357h-8.453zM136.655 31.561v16.357M132.428 40.128h8.437M133.936 47.918v4.594M139.874 47.918v4.594M145.517 43.999h19.6M147.419 56.072v21.504M131.451 67.254a7.983 7.983 0 0 1 7.983-7.984 7.983 7.983 0 0 1 7.985 7.984M135.222 76.738V60.842M144.268 61.317v15.42" />
        <path d="M150.794 56.072v21.164M131.451 56.072v21.164M145.695 85.206h2.642M159.176 85.206h2.642M151.485 85.206h4.543M145.695 89.6h2.642M159.176 89.6h2.642M151.485 89.6h4.543M145.695 93.994h2.642M159.176 93.994h2.642M151.485 93.994h4.543M158.193 68.933v7.805M151.268 68.933h13.849" />
        <path d="M163.107 24.597h-32.975M150.836 18.334a4.218 4.218 0 0 0-4.216-4.215 4.218 4.218 0 0 0-4.216 4.215 4.218 4.218 0 0 0 4.216 4.216 4.218 4.218 0 0 0 4.216-4.216zM146.62 14.119v8.431M150.836 18.335h-8.432" />
        <g strokeWidth={1.2}>
          <path d="M162.571 66.341a2.55 2.55 0 0 1-5.098 0h5.098z" />
          <path d="M159.919 66.341v-1.994M159.627 62.757c.53.601.292 1.678.292 1.678s-1.098.102-1.628-.499c-.53-.6-.292-1.678-.292-1.678s1.098-.102 1.628.499z" />
          <path d="M160.264 62.757c-.53.601-.292 1.678-.292 1.678s1.098.102 1.628-.499c.53-.6.293-1.678.293-1.678s-1.099-.102-1.629.499z" />
        </g>
        <path d="M128.123 51.997V31.561M128.123 77.236V56.76M126.298 52.512h40.643v3.561h-40.643zM126.299 27.758h38.818v3.561h-38.818zM122.651 94.151v4.117M123.992 90.034v4.117M125.332 85.918v4.117M126.671 81.801v4.117M126.298 77.577h40.643v3.56h-40.643zM128.434 47.918h17.083M165.117 98.268h-42.466M139.524 94.151h-16.873M141.895 90.034h-17.903M142.221 85.918h-16.873" />
        <path d="M166.941 22.582v5.651L146.62 8.962l-20.322 19.271V17.678" />
        <path d="M166.941 17.697v5.176L146.62 3.602l-20.322 19.271" />
      </g>
    </g>
    <g strokeWidth={0.25}>
      <g fill={lightGray}>
        <circle cx={20} cy={69} r={1} />
        <circle cx={15} cy={69} r={1} />
        <circle cx={101.25} cy={69} r={1} />
      </g>
      <circle cx={60.75} cy={69} r={1} fill={gray} />
      <circle cx={142} cy={69} r={1} fill={semiLightGray} />
    </g>
  </svg>
);

const SizedPaintedLadies = styled(PaintedLadies)`
  width: 150px;

  @media (min-width: 670px) {
    width: 185px;
  }

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 89px;

    @media (min-width: 670px) {
      height: 110px;
    }
  }
`;

export default SizedPaintedLadies;
