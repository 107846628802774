import styled from "styled-components";
import { blue, pink } from "../global/colors";

export const BaseH1 = styled.h1`
  color: ${pink};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.125em;
  line-height: 35px;
  text-transform: uppercase;

  @media (min-width: 670px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (min-width: 880px) {
    font-size: 38px;
    line-height: 49px;
  }
`;

export const SectionH1 = styled(BaseH1)`
  letter-spacing: 0.075em;
  text-transform: none;

  @media (min-width: 670px) {
    color: ${blue};
  }
`;

// Should be the same as the styles for the H2 component.
export const CardH1 = styled.h1`
  color: ${pink};
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 28px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;
