import React from "react";
import styled from "styled-components";
import { blue } from "../../global/colors";
import Octopus from "./images/octopus";

const Four04 = () => (
  // <head>
  //   <script>
  //     ga('set', 'page', '/404');
  //     ga('send', pageview');
  //     </script>
  // </head>
  <main>
    <StyledOctopus />
    <H1>404</H1>
  </main>
);

const StyledOctopus = styled(Octopus)`
  display: block;
  margin: 64px auto 0;
`;

const H1 = styled.h1`
  color: ${blue};
  font-size: 125px;
  font-weight: 600;
  line-height: 125px;
  margin-bottom: 24px;
  text-align: center;
`;

export default Four04;
