import React, { Fragment } from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Body from "./body";
import PaperPlane from "./images/plane";
import { JAPAN_HEADER } from "./data";

const Japan = () => (
  <Fragment>
    <main>
      <SectionHeader {...JAPAN_HEADER} svg={PaperPlane} />
      <Body />
    </main>
    <Footer />
  </Fragment>
);

export default Japan;
