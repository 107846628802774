import React from "react";
import styled from "styled-components";
import { lightGray } from "../../../global/colors";

const CableCar = props => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    stroke={lightGray}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2.25}
    viewBox="0 0 100 50"
    {...props}
  >
    <path d="M87.462 1.5h-75.15v7.137H3.527v35.542h92.717V26.765H85.433V8.637h2.028z" />
    <path d="M3.528 8.637v35.542h92.716V23.027" />
    <path d="M46.506 44.179h38.927V26.942H46.506zM12.312 1.5v7.137h75.15V1.5M94.223 1.5H5.55M85.433 26.942V8.637M46.506 26.942V8.637M65.97 26.942V8.637M90.863 23.159v21.02M96.245 26.942H85.433M16.258 8.637v35.542M31.382 8.637v35.542M46.506 30.823H3.528M65.97 14.143s3.68-1.352 9.731-1.352c6.052 0 9.732 1.352 9.732 1.352" />
    <path d="M46.726 14.143s3.68-1.352 9.732-1.352 9.732 1.352 9.732 1.352M14.68 44.179a3.606 3.606 0 0 1-7.212 0M21.893 44.179a3.605 3.605 0 0 1-7.212 0M29.105 44.179a3.606 3.606 0 0 1-7.213 0M64.942 44.179a3.605 3.605 0 0 1-7.212 0M72.155 44.179a3.606 3.606 0 0 1-7.213 0M79.368 44.179a3.606 3.606 0 0 1-7.212 0M3.528 37.133h42.978M98.273 8.637H1.5" />
  </svg>
);

const SizedCableCar = styled(CableCar)`
  width: 120px;
  @media (min-width: 670px) {
    width: 150px;
  }

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 60px;
    @media (min-width: 670px) {
      height: 75px;
    }
  }
`;

export default SizedCableCar;
