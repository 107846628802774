export const ACTIVITIES_HEADER = {
  title: "Tokyo",
  blurb:
    "Tokyo is a great place to explore if you love delicious food. " +
    "Please keep in mind that it՚s common for restaurants to only take " +
    "reservations for tourists through the concierge services offered by " +
    "hotels or fancy credit cards."
};

export const ACTIVITIES_DATA = [
  {
    id: 3,
    name: "☆ Fukamachi",
    categories: ["Food", "Tempura"],
    blurb:
      "I thought I didn՚t like tempura, turns out I haven՚t had good tempura " +
      "before. Hopefully you get seated at the short end of the bar for the " +
      "best view of the chefs at work.",
    url: "https://tabelog.com/en/tokyo/A1302/A130202/13004105/",
    michelin: "🌸",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 4,
    name: "☆ Gen Yamamoto",
    categories: ["Food", "Drinks"],
    blurb:
      "A super small cocktail bar that makes specialty drinks using seasonal " +
      "ingredients and fine liquors. You have the option to get the 4 or 6 " +
      "cocktail flight. Gen Yamamptp can accommodate people who prefer a low " +
      "alcoholic drinks.",
    url: "https://tabelog.com/en/tokyo/A1307/A130702/13154605/",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 5,
    name: "☆ Ghibli Museum",
    categories: ["Museum"],
    blurb:
      "Definitely worth the trip if you՚re able to buy tickets in advance. " +
      "The museum is nicely themed with all things Ghibli, has lots of " +
      "indoor and outdoor spaces to explore. Don՚t feel like you have to " +
      "save your Ghibli souvenir purchases for the museum though, I found " +
      "that I actually liked what I saw at other stores more. It took us " +
      "about 30 minutes to purchase tickets from California, so don՚t get " +
      "discouraged if you՚re struggling with connection to the website.",
    url: "http://www.ghibli-museum.jp/en/",
    price: "¥"
  },
  {
    id: 6,
    name: "Harajuku Gyozaro",
    categories: ["Food", "Dumplings"],
    blurb:
      "Great place if you՚re craving Japanese potstickers. I especially " +
      "liked their cucumber with miso appetizer. Be prepared to wait in line!",
    url: "https://tabelog.com/en/tokyo/A1306/A130601/13001284/",
    price: "¥"
  },
  {
    id: 7,
    name: "Hidemi Sugino",
    categories: ["Food", "Cakes"],
    blurb:
      "Try Hidemi Sugino if you want to enjoy fancy desserts and tea. From " +
      "what I recall, they use stars to indicate the alcoholic content of " +
      "their desserts.",
    url: "https://tabelog.com/en/tokyo/A1302/A130202/13002285/",
    price: "¥¥"
  },
  {
    id: 8,
    name: "☆ Hotel Gracery Shinjuku",
    categories: ["Hotel"],
    blurb:
      "Nice hotel in a convenient location. Rooms are small but the " +
      "bathrooms are amazing. The hotel also has easy access to the Godzilla " +
      "statue that towers over Shinjuku, we couldn՚t resist the photo " +
      "opportunity.",
    url: "http://shinjuku.gracery.com/",
    price: "¥"
  },
  {
    id: 9,
    name: "☆ Ishibashi",
    categories: ["Food", "Hot pot"],
    blurb:
      "This is most definitely our favorite meal from the entire trip! " +
      "Delicious Kobe beef cooked at your table sukiyaki style. Our one " +
      "regret was that we didn՚t go with an empty stomach, would have loved " +
      "to try the rice dish.",
    url: "https://tabelog.com/en/tokyo/A1311/A131101/13021036/",
    michelin: "🌸",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 10,
    name: "☆ Ishikawa",
    categories: ["Food", "Kaiseki"],
    blurb:
      "Considering how much we enjoyed the food and the cost of the meal, it " +
      "was a deal. Similar meals cost much more in California. It was CC՚s " +
      "favorite kaiseki meal of the trip.",
    url: "https://tabelog.com/en/tokyo/A1309/A130905/13004079/",
    michelin: "🌸🌸🌸",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 11,
    name: "Katsusai",
    categories: ["Food", "Tonkatsu"],
    blurb:
      "All you can eat shredded cabbage with katsu restaurant located in the " +
      "mall. We liked the standard katsu more than rolled pork and tomato + " +
      "pesto options.",
    url: "https://tabelog.com/en/tokyo/A1303/A130302/13010828/",
    price: "¥"
  },
  {
    id: 12,
    name: "☆ Kosoan",
    categories: ["Food", "Tea"],
    blurb:
      "A Japanese tea house in a traditional tea environment, only they՚re " +
      "not afraid of more modern ways to prepare tea. Each drink order comes " +
      "with a small dessert that pairs nicely with the tea.",
    url: "https://tabelog.com/en/tokyo/A1317/A131703/13003216/",
    price: "¥"
  },
  {
    id: 13,
    name: "Pierre Hermé",
    categories: ["Food", "Macarons"],
    blurb:
      "If you՚re planning a trip to Paris, you should wait until you can go " +
      "to Pierre Hermé there. They at least pass the passion fruit macron " +
      "test.",
    url: "https://tabelog.com/en/tokyo/A1302/A130201/13045668/",
    price: "¥¥"
  },
  {
    id: 14,
    name: "☆ Pizza Studio Tamaki",
    categories: ["Food", "Pizza"],
    blurb:
      "No regrets about eating pizza in Japan, it was delicious. Forever " +
      "changed what we expect from a wood fired pizza. They even use juice " +
      "ice cubes in their drinks!",
    url: "https://tabelog.com/en/tokyo/A1314/A131401/13205741/",
    price: "¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 15,
    name: "Shinbashi Torishige Donburiko",
    categories: ["Food", "Oyakodon"],
    blurb:
      "An oyakodon place in the Tokyo Station located past the collection of " +
      "souvenir dessert booths. We recommend that you get oyakodon, it՚s " +
      "much better in Japan. This restaurant cooks the egg on the rare side, " +
      "making it better than anything we՚ve had in America.",
    url: "https://tabelog.com/en/tokyo/A1302/A130201/13025535/",
    price: "¥"
  },
  {
    id: 16,
    name: "Sobadokoro Yamato",
    categories: ["Food", "Soba"],
    blurb:
      "I thought I didn՚t like soba, I was wrong again. " +
      "If you happen to be in the area shopping for fake display food or " +
      "various kitchen wares, consider stopping here for a bite.",
    url: "https://tabelog.com/en/tokyo/A1311/A131102/13056388/",
    price: "¥"
  },
  {
    id: 17,
    name: "Straw Hat Café",
    categories: ["Food", "Cafe"],
    blurb:
      "Only eat here if you՚re at the Ghibli Museum and the line happens to " +
      "be short. When we went, the line started at the circle of chairs just " +
      "outside the café doors. If you՚re looking for food that՚s nicely " +
      "Ghibli themed, make sure to check for pictures online. Some dishes " +
      "are only themed with a little Ghibli character flag.",
    url: "https://tabelog.com/en/tokyo/A1320/A132002/13010001/",
    price: "¥"
  },
  {
    id: 18,
    name: "☆ Sushi Arai",
    categories: ["Food", "Sushi"],
    blurb:
      "This was CC՚s favorite sushi place from the trip. The fish and the " +
      "rice was delicious, I thought the sushi chef was a little heavy on " +
      "the wasabi though. For what it՚s worth, CC liked this restaurant " +
      "more than the other place because he thought the rice was much better.",
    url: "https://tabelog.com/en/tokyo/A1301/A130101/13188186/",
    michelin: "🌸",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 19,
    name: "Tagetsu",
    categories: ["Food", "Kaiseki"],
    blurb:
      "We may have gone a little crazy with the photos at this restaurant. " +
      "It was our first kaiseki place, we were in a private room, and every " +
      "dish was photogenic. We learned that Ayu fish served whole is meant " +
      "to be enjoyed from head to tail, minus the fins. Warning, when eaten " +
      "whole, Ayu is very bitter.",
    url: "https://tabelog.com/en/tokyo/A1306/A130602/13160021/",
    michelin: "🌸",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 20,
    name: "☆ Takumi Shingo",
    categories: ["Food", "Sushi"],
    blurb:
      "This was my favorite sushi restaurant from the trip. The fish was " +
      "delicious and the sushi chef didn՚t go crazy with the wasabi. (I " +
      "honestly couldn՚t tell the difference between the rice, but for " +
      "whatever reason, CC like the other restaurant՚s rice more.",
    url: "https://tabelog.com/en/tokyo/A1306/A130603/13155925/",
    price: "¥¥¥",
    reservation: "(Reservation Required)"
  },
  {
    id: 21,
    name: "☆ The Roastery",
    categories: ["Food", "Coffee"],
    blurb:
      "Want a cup of good hipster coffee? This is your place. Just be " +
      "careful when ordering, when we went they assumed we wanted two cups " +
      "instead of just one. Probably just a CC & TGP problem though, most " +
      "people who walk into a fancy coffee shop want order a drink.",
    url: "https://tabelog.com/en/tokyo/A1303/A130301/13161140/",
    price: "¥¥"
  },
  {
    id: 22,
    name: "☆ The Tokyo Station Hotel",
    categories: ["Hotel"],
    blurb:
      "This is probably the nicest hotel we՚ll ever book. We mainly stayed " +
      "here so we could use their concierge service to book all of our " +
      "fancier restaurant reservations. They can even help you convey dietary" +
      " restrictions at restaurants that don՚t speak English. (Booked with " +
      "credit card points)",
    url: "https://www.thetokyostationhotel.jp/en",
    price: "¥¥¥"
  },
  {
    id: 24,
    name: "☆ Yomiuri Giants",
    categories: ["Sports", "Baseball"],
    blurb:
      "We highly recommend watching a baseball game in Japan. The cheering " +
      "sections for each team creates a fun atmosphere that can make any " +
      "baseball-adverse person enjoy the game. Also, order a hot dog so you " +
      "can marvel at the ingenuity of Japanese design. You can open and " +
      "squeeze your ketchup with one hand!",
    url: "http://www.giants.jp/en/ticket/",
    price: "¥¥"
  }
];
