import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import H2 from "../../components/h2";
import H3 from "../../components/h3";
import { BaseP as P } from "../../components/p";
import { BaseP as P1 } from "../../components/p1";
import { GridCard } from "../../components/card";
import { lightPink } from "../../global/colors";

const ActivitiesCard = ({
  name,
  categories,
  blurb,
  url,
  michelin,
  price,
  reservation
}) => (
  <A href={url} target="_blank">
    <Card>
      <ActivitiesH2>{name}</ActivitiesH2>
      <H3>{categories.join(" • ")}</H3>
      <P>{blurb}</P>
      {michelin && <P>{michelin}</P>}
      <P1>{price}</P1>
      {reservation && <P1>{reservation}</P1>}
    </Card>
  </A>
);

ActivitiesCard.propTypes = {
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  blurb: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  michelin: PropTypes.string,
  price: PropTypes.string,
  reservation: PropTypes.string
};

const A = styled.a`
  display: block;
  transition: transform 0.15s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Card = styled(GridCard)`
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  transition: box-shadow 0.15s;

  /* stylelint-disable-next-line */
  ${A}:hover & {
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
  }
`;

const ActivitiesH2 = styled(H2)`
  transition: color 0.15s;

  /* stylelint-disable-next-line */
  ${A}:hover & {
    color: ${lightPink};
  }
`;

export default ActivitiesCard;
