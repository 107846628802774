import React from "react";
import { Grid, GridWrapper } from "../../components/grid";
import Card from "./card";
import { JAPAN_DATA } from "./data";

const JapanBody = () => (
  <GridWrapper>
    <Grid>
      {JAPAN_DATA.map(hotel => (
        <Card key={hotel.id} {...hotel} />
      ))}
    </Grid>
  </GridWrapper>
);

export default JapanBody;
