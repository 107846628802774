import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import H2 from "../../components/h2";
import ListItem from "../../components/li";
import { GridCard } from "../../components/card";
import { Image, ImageRatioFixer } from "../../components/img";

const JapanCard = ({ path, imageUrl, name, duration, blurb }) => (
  <Link to={path}>
    <Card>
      <HotelImageRatioFixer>
        <Image src={imageUrl} alt="" />
      </HotelImageRatioFixer>
      <H2>{name}</H2>
      <Ul>
        <ListItem>
          <strong>Trip Duration:</strong> {duration}
        </ListItem>
      </Ul>
      {blurb}
    </Card>
  </Link>
);

JapanCard.propTypes = {
  path: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  blurb: PropTypes.node.isRequired
};

const Card = GridCard.extend`
  padding: 16px;
  min-height: 420px;
`;

const HotelImageRatioFixer = ImageRatioFixer.extend`
  margin: -16px -16px 16px;
  padding-bottom: calc((100% + 32px) * 0.434375);
  width: calc(100% + 32px);
`;

const Ul = styled.ul`
  margin: 8px 0;
`;

export default JapanCard;
