import React from "react";
import styled from "styled-components";
import { Grid, GridWrapper } from "../../components/grid";
import Card from "./card";
import { ACTIVITIES_DATA } from "./data";

const ActivitiesBody = () => (
  <GridWrapper>
    <ActivitiesGrid>
      {ACTIVITIES_DATA.map(hotel => (
        <Card key={hotel.id} {...hotel} />
      ))}
    </ActivitiesGrid>
  </GridWrapper>
);

const ActivitiesGrid = styled(Grid)`
  @supports (display: grid) {
    @media (min-width: 880px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 1112px;
    }
  }
`;

export default ActivitiesBody;
