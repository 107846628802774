export const WIP_HEADER = {
  title: "Work in Progress",
  blurb: " "
};

export const WIP_DATA = [
  {
    title: "Sorry",
    details: [
      "This page isn’t quite ready yet. I hope to be able to share it with you soon!"
    ],
    address: [""],
    id: 1
  },
  {
    title: "One More Thing",
    details: [
      "If you’re planning a trip here, please reach out and I can do my best to get the information up for you. This is a hobby after all and life likes to get in the way of hobbies."
    ],
    address: [""],
    id: 2
  }
];
