import React from "react";
import styled from "styled-components";
import { lightGray } from "../../../global/colors";

const Plane = props => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    stroke={lightGray}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    strokeWidth={0.8}
    viewBox="0 0 57 22"
    {...props}
  >
    <path d="M39.376 13.763l.276-2.263 3.919-1.656 12.817 11.3-22.972-5.008 3.918-3.256 19.054 8.264M39.763 11.555l16.625 9.589" />
    <path d="M37.303 11.299c-.175-.13-.357-.256-.543-.376" />
    <path
      d="M34.982 9.993c-2.394-.983-5.216-1.134-7.518.382-1.556 1.026-3.756 1.722-6.467 1.045-2.74-.683-6.336-3.17-5.903-6.495.434-3.325 3.558-5.03 6.308-4.304s5.182 4.459 2.673 7.558c-2.629 3.249-9.963 2.842-12.631.64-2.424-2-7.307-6.484-11.912-4.104"
      strokeDasharray="1.34,2.01"
    />
    <path d="M38.383 17.405l.331 1.546 17.674 2.193" />
  </svg>
);

const SizedPlane = styled(Plane)`
  margin-left: -12px;
  margin-bottom: 16px;
  width: 160px;

  /* Target Internet Explorer versions 10 and 11. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 62px;
  }
`;

export default SizedPlane;
