import React, { Fragment } from "react";
import Footer from "../../app/footer";
import SectionHeader from "../../components/section-header";
import Card from "./card";
import CableCar from "./images/cable-car";
import { WIP_HEADER } from "./data";

const WIP = () => (
  <Fragment>
    <main>
      <SectionHeader {...WIP_HEADER} svg={CableCar} />
      <Card />
    </main>
    <Footer />
  </Fragment>
);

export default WIP;
