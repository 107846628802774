// Globals and polyfills.
import "core-js/es6/symbol";
import "core-js/fn/array/from";
import "core-js/fn/map";
import "./global/css";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import App from "./app";

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
