import React, { Fragment } from "react";
import { BaseP } from "../../components/p";
import koyasanImage from "./images/koyasan-bw.jpg";
import kyotoImage from "./images/kyoto-parade.jpg";
import naraImage from "./images/nara-bw.jpg";
import tokyoImage from "./images/tokyo-bw.jpg";

export const JAPAN_HEADER = {
  title: "Japan",
  blurb:
    "Japan is a lovely place to visit and it will always be a special place " +
    "to us, it was where we got engaged!! Just be warned, summers in Japan " +
    "can be very hot and humid! We recommend visiting in the Spring or Fall " +
    "instead. Also, don't be afraid to try food from other parts of the " +
    "world. Japanese appreciation for craftsmanship knows no cultural bounds."
};

export const JAPAN_DATA = [
  {
    id: 2,
    path: "/tokyo",
    imageUrl: `${tokyoImage}`,
    name: "Tokyo",
    country: "Japan",
    duration: "8 Days",
    blurb: (
      <Fragment>
        <BaseP>
          After the family reunion portion of the trip, our first stop was{" "}
          Tokyo. The best part of this visit would have to be the delicious
          food. All within reach thanks to the great public transit systems
          available in the city.
        </BaseP>
      </Fragment>
    ),
    bookUrl: "http://www.hayesvalleyinn.com/en-us"
  },
  {
    id: 3,
    path: "/wip",
    imageUrl: `${kyotoImage}`,
    name: "Kyoto",
    country: "Japan",
    duration: "5 Days",
    blurb: (
      <Fragment>
        <BaseP>Coming Soon!</BaseP>
      </Fragment>
    ),
    bookUrl: "https://www.metrohotelsf.com"
  },
  {
    id: 4,
    path: "/wip",
    imageUrl: `${naraImage}`,
    name: "Nara",
    country: "Japan",
    duration: "1 Day",
    blurb: (
      <Fragment>
        <BaseP>Coming Soon!</BaseP>
      </Fragment>
    ),
    bookUrl: "http://www.hayesvalleyinn.com/en-us"
  },
  {
    id: 5,
    path: "/wip",
    imageUrl: `${koyasanImage}`,
    name: "Koyasan (Mount Kōya)",
    country: "Japan",
    duration: "1 Day",
    blurb: (
      <Fragment>
        <BaseP>Coming Soon!</BaseP>
      </Fragment>
    ),
    bookUrl: "http://www.hayesvalleyinn.com/en-us"
  }
];
