import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import About from "../pages/about";
import Home from "../pages/home";
import Japan from "../pages/japan";
import Tokyo from "../pages/tokyo";
import Four04 from "../pages/404";
import WIP from "../pages/wip";
import Header from "./header";

const App = () => (
  <Fragment>
    <Header />
    <Switch>
      <Route exact path="/" render={Home} />
      <Route exact path="/about" render={About} />
      <Route exact path="/japan" render={Japan} />
      <Route exact path="/tokyo" render={Tokyo} />
      <Route exact path="/wip" render={WIP} />
      <Route render={Four04} />
    </Switch>
  </Fragment>
);

export default App;
